<template>
  <NavBar></NavBar>
  <v-carousel hide-delimiters show-arrows="hover" class="h-screen">
    <v-carousel-item cover class="banner d-flex align-end">
      <v-row class="d-class justify-center align-center h-100">
        <v-col cols="6" lg="6" class="d-none d-lg-block"></v-col>

        <v-col cols="12" lg="6" class="d-flex justify-center">
          <div class="pa-lg-10 pa-5 center filter text-md-center text-lg-start">
            <h1
              class="text-lg-h4 text-h5 fw-bolder text-white mt-lg-5 mt-5 mb-0 animate__animated animate__backInDown"
            >
              MY
              <span class="text-green-darken-1">HOME TUTOR</span>
            </h1>
            <p
              class="text-body-1 text-white animate__animated animate__bounceInRight"
            >
              Get your child a professional and qualified home teacher
              <span class="d-none d-lg-block d-md-block">
                and achieve the results you desire.
              </span>
            </p>
            <div class="d-block me-lg-5">
              <v-select
                variant="solo"
                multiple
                label="Select Subjects"
                chips
                density="compact"
                :items="['English Lang.', 'Mathematics', 'Science', 'Other']"
              ></v-select>
              <NuxtLink to="/form" class="text-white">
                <v-btn
                  color="green-darken-4"
                  size="x-large"
                  variant="elevated"
                  append-icon="mdi-arrow-right-circle"
                  class="px-lg-2 px-5 animate__animated animate__bounce animate__delay-2s"
                >
                  request tutor
                </v-btn>
              </NuxtLink>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-carousel-item>

    <v-carousel-item cover class="counsel-banner">
      <v-row class="d-class justify-center align-center h-100">
        <v-col></v-col>
        <v-col cols="12" lg="6" class="d-flex justify-center">
          <div class="pa-lg-10 pa-5 center filter">
            <h1
              class="text-h4 text-white fw-bolder mt-lg-5 mt-5 mb-0 animate__animated animate__backInDown"
            >
              NEED OF
              <span class="text-green-darken-1"
                >GUIDANCE?
                <i
                  class="fa text-body-1 text-white fa-arrow-circle-o-right"
                  aria-hidden="true"
                ></i>
              </span>
            </h1>
            <p
              class="text-body-1 text-white fs-5 mt-0 animate__animated animate__bounceInRight"
            >
              Speak to a professional counsellor to assist you with your
              challenge.
            </p>
            <v-btn color="green-darken-4" size="x-large">
              <NuxtLink id="bookBtn" to="/counselling" class="text-white"
                >BOOK A SESSION</NuxtLink
              >
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-carousel-item>

    <v-carousel-item cover class="consult-banner">
      <v-row class="d-class justify-center align-center h-100">
        <v-col cols="6" lg="6" class="d-none d-lg-block"></v-col>
        <v-col cols="12" lg="6" class="d-flex justify-center">
          <div class="pa-lg-10 pa-5 center filter">
            <h1
              class="text-h4 fw-bolder text-white mt-lg-5 mt-5 mb-0 animate__animated animate__backInDown"
            >
              GROW YOUR
              <span class="text-green-darken-1 shadow-two">SCHOOL</span>
            </h1>
            <p
              class="text-body-1 text-white animate__animated animate__bounceInRight"
            >
              Consult with us and let us help your school reach its potential.
              We are the professionals...
            </p>
            <v-btn color="green-darken-4" size="x-large">
              <NuxtLink class="text-white" id="bookBtn" to="/consultancy"
                >LET'S START...</NuxtLink
              >
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Carousel",
};
</script>

<style scoped>
.parent-quote {
  font-size: 20px;
  line-height: 1.5;
}

.center {
  text-align: left;
  width: 80%;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .parent-quote {
    font-size: 12px;
    line-height: 1.5;
  }

  .center {
    text-align: center;
    width: 80%;
  }
}
</style>
